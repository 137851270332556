<template>
    <div>
        <slot name="header"></slot>
        <div class="header" v-if="showTypeAndScope">{{ value.COMPETITION_TYPE.name }} - échelon {{ value.SCOPE.name }}</div>
        <h3 class="title">{{ (value != null) ? value.name : '' }}</h3>
        <h2 class="title"><slot name="title"></slot></h2>
        <div v-if="showState">{{ value.isComplete ? "Terminée" : ( value.isStarted ? "Commencée" : "Non-commencée")}}</div>

        <slot>
        </slot>
        <slot name="backButton"><b-button v-if="showBackButton" class="back" @click="goBack"><slot name="backButtonText">&lt;&lt; Retour</slot></b-button></slot>
        <slot name="errors">
          <errors-viewer :errors="elementsToCorrect">
          </errors-viewer>
        </slot>
        <slot name="warnings">
          <fieldset class="fieldset warning" v-if="showWarnings || warnings.length > 0">
            <legend>Avertissements</legend>
            <ul>
              <li v-for="element in warningElements" :key="element.key">
                {{ element.value }}
              </li>
            </ul>
          </fieldset>
        </slot>
        <slot name="footer"/>
    </div>
</template>

<script>
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import ErrorsViewer from './ErrorsViewer.vue';
export default
{
  components:{ ErrorsViewer },
    props: { value: { type: Object, required: true },
              showErrors: { type: Boolean, default: false},
              showWarnings: { type:Boolean, default: false},
              showTypeAndScope: { type: Boolean, default: true },
              showBackButton: { type: Boolean, default: true},
              showState: { type: Boolean, default: true},
          },
    computed:{
        errors(){
            return (this.value != null)
                ? CompetitionsConfigurationHelper.missingRequirementsBeforeRun(this.value.id).map(err => { return { key: err._no, value: err._fr};})
                : '';
        },
        elementsToCorrect(){
          return (this.value != null) ? this.value.ConfigurationErrors : null;
          //return this.errors;
        },
        warnings(){
            return (this.value != null)
                ? CompetitionsConfigurationHelper.warningsBeforeRun(this.value.id)
                : '';
        },
        warningElements(){
          var i = 0;
          return this.warnings.map(element => { return {key: 'W' + i++, value: element}});
        }
    },
}
</script>

<style>

</style>
